import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { AiOutlineDropbox } from 'react-icons/ai';
import { IoMdAddCircle } from 'react-icons/io';
import { ButtonItens, Container } from './styles';
import { toast } from 'react-toastify';

import { useNFOutrasOperacao } from '../../NFOutrasOperacoesContext';
import getFornecedores from '../../services/getFornecedores';
import getSeries from '../../services/getSeries';
import getTipoAjustes from '~/pages/NFOutrasOperacoes/services/getTipoAjustes';

import Loja from '~/components/Loja';
import { InputSelect } from '~/pages/NFOutrasOperacoes/myComponents/InputSelect';
import {
  InputNumber,
  InputDate,
  InputTextArea,
  InputAsyncSelect,
  InputText,
} from '~/components/NovosInputs';
import Separator from '~/components/Separator';

import { SelectProps } from '../../protocols/SelectProps';
import ModalNovoItem from './ModalNovoItem';
import ModalImportarAjuste from './ModalImportarAjuste';
import TableItens from './TableItens';

import getPerfis from '../../services/getPerfils';
import getCfops from '../../services/getCfops';
import getTributacoes from '../../services/getTributacoes';
import { PerfilProps } from '../../protocols/PerfilProps';
import getNcm from '../../services/getNcm';
import getEspecies from '../../services/getEspecies';
import { getUserData } from '~/services/user';
import api from '~/services/api';

export const Nfe: React.FC = () => {
  const {
    OpenModal,
    OpenModalAjuste,
    setFornecedor,
    fornecedores,
    setFornecedores,
    setPerfil,
    perfil,
    perfis,
    serieSelect,
    setSerieSelect,
    serieList,
    setSeriesList,
    especieList,
    setEspecieList,
    setPerfis,
    setTipo_ajustes,
    setCfops,
    setTributacoes,
    setNcms,
    loja,
    setLoja,
    setValue,
    fornecedor,
    errors,
    itens,
    register,
    control,
    setDtaEmissao,
    dtaEmissao,
    setDtaEntrada,
    dtaEntrada,
    resetModalAjuste,
    setNumNF,
    numNF,
    isBlockNf,
    update,
    clearErrors,
    getValues,
  } = useNFOutrasOperacao();
  const user = getUserData();

  useEffect(() => {
    (async () => {
      const { data } = await api.get(`/lojas/${user.loja}`);
      if (data.success) {
        setValue('cod_loja', user.loja);
        setLoja({
          cod_loja: data.data.cod_loja,
          des_loja: data.data.des_loja,
          label:
            data.data.cod_loja < 10
              ? `0${data.data.cod_loja} - ${data.data.des_loja} (${data.data.des_cidade})`
              : `${data.data.cod_loja} - ${data.data.des_loja} (${data.data.des_cidade})`,
          selected: true,
          uf: data.data.des_uf,
          value: data.data.cod_loja,
          num_cnpj: data.data.num_cnpj,
        });
      }
    })();
  }, [user.loja]);

  useEffect(() => {
    (async () => {
      if (loja?.cod_loja !== undefined) {
        const ResultadoPerfils: SelectProps[] = await getPerfis(
          loja?.cod_loja,
          fornecedor?.flg_fornecedor,
          fornecedor?.des_uf !== loja?.uf,
        );
        setPerfis(ResultadoPerfils);
      }
    })();
  }, [
    loja?.cod_loja,
    loja?.uf,
    fornecedor?.des_uf,
    fornecedor?.flg_fornecedor,
  ]);

  useEffect(() => {
    (async () => {
      if (perfil.cod_perfil) {
        const data = await getCfops(perfil.cod_perfil);
        setCfops(data);
      }
    })();
  }, [perfil]);

  useEffect(() => {
    (async () => {
      // Tributacoes
      const dataTributacoes = await getTributacoes();
      setTributacoes(dataTributacoes);
      // Series
      if (loja?.cod_loja !== undefined) {
        const dataSeries = await getSeries(loja?.cod_loja);
        setSeriesList(dataSeries);
      }
      // Ncm
      const dataNcm = await getNcm();
      setNcms(dataNcm);
    })();
  }, [loja?.cod_loja]);

  useEffect(() => {
    (async () => {
      if (perfil?.tipo_operacao >= 0) {
        const data = await getTipoAjustes(perfil?.tipo_operacao);
        setTipo_ajustes(data);
      }

      const dataEspecies: any = await getEspecies(perfil?.tipo_operacao);
      setEspecieList(dataEspecies);
      const especie = dataEspecies.find((i: any) => i.des_especie === 'NFE');
      if (perfil?.tipo_emissao === 1 && !update) {
        setSerieSelect('');
        setValue('num_especie', {
          value: especie?.value,
          label: 'NFE - NOTA FISCAL ELETRÔNICA',
          des_doc_fiscal: 'NOTA FISCAL ELETRÔNICA',
          cod_doc_fiscal: '55',
          num_modelo_fiscal: '',
          des_especie: especie?.value,
        });
        if (typeof getValues('num_serie') !== 'string') return;
        setValue('num_serie', '');
        if (serieList.length === 1) setValue('num_serie', serieList[0]);
      }
    })();
  }, [perfil, getValues]);

  async function handleFornecedor(selected: any) {
    setValue('cod_pessoa', selected);
    setPerfil({} as PerfilProps);
    if (!update) setValue('cod_perfil', '');
    if (selected?.cod_pessoa === undefined) return;
    const { data } = await api.get(
      `/nf-outras-operacoes/validacao-endereco/${selected.cod_pessoa}`,
    );
    if (!data.success) {
      setFornecedor(selected);
      toast.warning(data.message);
    } else {
      setFornecedor(selected);
    }
  }

  return (
    <Container>
      <ModalNovoItem />
      <ModalImportarAjuste />
      <Row>
        <Col md={5} sm={12} style={{ marginTop: '10px' }}>
          <Loja
            selectedLoja={loja?.cod_loja}
            IsInvalid={!!errors.cod_loja}
            isDisabled={itens.length > 0}
            onChangedObject={(e) => {
              setLoja(e);
            }}
            onChange={(e: number) => {
              setValue('cod_loja', e);
              if (!update && loja?.cod_loja !== e) {
                setValue('cod_perfil', '');
                setPerfil({} as PerfilProps);
              }
            }}
          />
        </Col>
        <Col md={7} sm={12}>
          <InputAsyncSelect
            label="Fornecedor"
            maxLength={50}
            placeholder="Selecione..."
            name="cod_pessoa"
            register={register}
            isError={!!errors.cod_pessoa}
            control={control}
            disabled={itens.length > 0}
            changeSelected={(selected) => {
              handleFornecedor(selected);
              clearErrors('cod_pessoa');
            }}
            api={{
              route: '/nf-outras-operacoes/fornecedor',
              method: 'get',
              fildDescriptionForValue: 'cod_pessoa',
              fields: ['cod_fornecedor', 'des_fornecedor', 'num_cpf_cnpj'],
            }}
          />
        </Col>
      </Row>

      <Row>
        <Col md={4} sm={12}>
          <InputSelect
            label="Perfil"
            id="DropDownWidth"
            ListaWidth="650px"
            placeholder="Selecione..."
            name="cod_perfil"
            register={register}
            isError={!!errors.cod_perfil}
            disabled={itens.length > 0}
            control={control}
            options={perfis}
            changeSelected={(selected) => {
              const perfilSelect: any = selected;
              setValue('num_serie', '');
              setValue('num_especie', '');
              setPerfil(perfilSelect);
              setValue('cod_perfil', selected);
              resetModalAjuste();
              clearErrors('cod_perfil');
            }}
          />
        </Col>
        <Col md={1} sm={12}>
          {perfil.tipo_emissao === 1 ? (
            <InputSelect
              label="Série"
              value={serieSelect === undefined ? '' : serieSelect}
              name="num_serie"
              placeholder="Selecione..."
              disabled={isBlockNf}
              register={register}
              max={4}
              onChangeCapture={(event: any) => {
                if (perfil.tipo_emissao !== 1) {
                  const value = event.target.value.replace(/[^a-z0-9]/gi, '');
                  setSerieSelect(value);
                  setValue('num_serie', {
                    value: Number(value),
                    label: value,
                  });
                }
              }}
              isError={!!errors.num_serie}
              control={control}
              options={serieList}
              changeSelected={(selected) => {
                setValue('num_serie', selected);
                setSerieSelect(selected.value.toString());
                clearErrors('num_serie');
              }}
            />
          ) : (
            <InputText
              label="Série"
              name="num_serie"
              placeholder="Informe a série"
              caseInput="upper"
              toLowerCase={false}
              register={register}
              isError={!!errors.num_serie}
              disabled={isBlockNf}
              onKeyDown={(e) => {
                if (e.key === ' ' || e.code === 'Space') {
                  e.preventDefault();
                }
              }}
              onPaste={(e) => {
                e.preventDefault();
                const pastedText = e.clipboardData.getData('text');
                const modifiedText = pastedText.replace(/\s+/g, '');
                const inputElement = e.target as HTMLInputElement;
                inputElement.value = modifiedText;
              }}
              maxLength={8}
            />
          )}
        </Col>
        <Col md={1} sm={12}>
          <InputSelect
            label="Espécie"
            id="DropDownWidth"
            placeholder=""
            name="num_especie"
            ListaWidth="520px"
            disabled={isBlockNf || perfil.tipo_emissao === 1}
            register={register}
            isError={!!errors.num_especie}
            control={control}
            options={especieList}
            changeSelected={(selected: any) => {
              setValue('num_especie', {
                ...selected,
                value: selected.value,
                label: `${selected.des_especie} - ${selected.des_doc_fiscal}`,
              });
              clearErrors('num_especie');
            }}
          />
        </Col>
        <Col md={2} sm={12}>
          <InputNumber
            label="Nº NF"
            maxLength={9999999999}
            placeholder=""
            name="num_nf"
            onChange={(e: any) => {
              setValue('num_nf', e.target.value);
              setNumNF(e.target.value);
            }}
            min={0}
            max={9999999999}
            value={!update && perfil.tipo_emissao === 1 ? '' : numNF}
            register={register}
            control={control}
            disabled={isBlockNf || perfil.tipo_emissao === 1}
            isError={!!errors.num_nf}
          />
        </Col>
        <Col md={2} sm={12}>
          <InputDate
            label="Emissão"
            placeholder=""
            value={dtaEmissao}
            name="dta_emissao"
            register={register}
            control={control}
            disabled={isBlockNf}
            onChange={(event: any) => {
              setDtaEmissao(event.target?.value);
            }}
            isError={!!errors.dta_emissao}
            typeError={errors.dta_emissao?.message}
          />
        </Col>
        <Col md={2} sm={12}>
          <InputDate
            label="Entrada/Saida"
            placeholder=""
            name="dta_entrada"
            value={dtaEntrada}
            register={register}
            control={control}
            disabled={update ? isBlockNf : false}
            isError={!!errors.dta_entrada}
            onChange={(event: any) => {
              setDtaEntrada(event.target?.value);
            }}
            typeError={errors.dta_entrada?.message}
          />
        </Col>
      </Row>
      <Row>
        <Col md={8} sm={12}>
          <Separator labelText="Itens" sidePadding="0 0" />
        </Col>
        <Col md={2} sm={6}>
          <ButtonItens
            onClick={OpenModalAjuste}
            disabled={isBlockNf}
            type="button"
          >
            <AiOutlineDropbox color="#FEA535" />
            <span>Importar Ajustes</span>
          </ButtonItens>
        </Col>
        <Col md={2} sm={6}>
          <ButtonItens onClick={OpenModal} disabled={isBlockNf} type="button">
            <IoMdAddCircle color="#2D7BE8" />
            <span>Incluir Novo</span>
          </ButtonItens>
        </Col>
      </Row>
      <br />
      <Row>
        <TableItens />
      </Row>
      <br />
      <br />
    </Container>
  );
};
